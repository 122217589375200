var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{staticStyle:{"padding":"0px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{staticStyle:{"background-color":"#fff","line-height":"60px","margin":"0","width":"100%","height":"60px"}},[_c('div',{staticClass:"Enter-top"},[_c('div',[_c('div',[_c('a-breadcrumb',{staticStyle:{"margin":"18px 0"}},[_c('a-breadcrumb-item',[_vm._v("小程序")]),_c('a-breadcrumb-item',[_vm._v("工作台")])],1)],1)]),(!_vm.visible)?_c('div',{staticClass:"Enter-top-right"},[_c('a-button',{staticStyle:{"margin":"0 15px"},attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.showDrawer.apply(null, arguments)}}},[_vm._v(" 新增模块 ")])],1):_c('div',[_c('a-icon',{staticStyle:{"width":"30px","margin-right":"5px","cursor":"pointer","font-size":"25px"},attrs:{"type":"rollback"},on:{"click":() => {
              this.Id = ''
              this.visible = false
            }}})],1)])]),(!_vm.visible)?_c('a-layout',{staticStyle:{"padding":"24px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{style:({
        background: '#fff',
        padding: '24px',
        margin: 0,
        overflow: _vm.auto,
      })},[_c('div',{staticClass:"search-wrap"},[_c('div',{staticStyle:{"width":"100%"}},[_c('a-table',{attrs:{"row-selection":_vm.rowSelection,"rowKey":"id","pagination":false,"columns":_vm.columns,"data-source":_vm.dashboardlist,"rowClassName":_vm.rowClassName},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [_c('a',{on:{"click":function($event){return _vm.onEdit(record)}}},[_vm._v("编辑")]),_vm._v("   "),_c('a-popconfirm',{attrs:{"title":"你确认要删除该模块么?","ok-text":"是","cancel-text":"否"},on:{"confirm":function($event){return _vm.onDetele(record)},"cancel":_vm.cancel}},[_c('a',[_vm._v("删除")])])]}},{key:"status",fn:function(text, record){return _c('span',{},[_c('a-switch',{attrs:{"checked":text == 1 ? true : false,"default-checked":""},on:{"change":function($event){return _vm.onstate(record)}}})],1)}}],null,false,1030497708)}),_c('a-pagination',{attrs:{"page-size":_vm.params.pageSize,"show-quick-jumper":"","default-current":2,"total":_vm.total},on:{"change":_vm.onpagesize},model:{value:(_vm.params.current),callback:function ($$v) {_vm.$set(_vm.params, "current", $$v)},expression:"params.current"}})],1)])])],1):_c('a-layout',{staticStyle:{"padding":"24px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{style:({
        background: '#fff',
        padding: '24px',
        margin: 0,
        overflow: _vm.auto,
      })},[_c('div',[_c('a-form-model',{ref:"ruleForm",attrs:{"rules":_vm.rules,"model":_vm.form,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-form-model-item',{attrs:{"label":"名称"}},[_c('a-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"路径"}},[_c('a-input',{model:{value:(_vm.form.path),callback:function ($$v) {_vm.$set(_vm.form, "path", $$v)},expression:"form.path"}})],1),_c('a-form-model-item',{attrs:{"label":"序号"}},[_c('a-input',{model:{value:(_vm.form.num),callback:function ($$v) {_vm.$set(_vm.form, "num", $$v)},expression:"form.num"}})],1),_c('a-form-model-item',{attrs:{"label":"颜色"}},[_c('a-input',{model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}})],1),_c('a-form-model-item',{attrs:{"label":"图标"}},[_c('a-input',{model:{value:(_vm.form.logo),callback:function ($$v) {_vm.$set(_vm.form, "logo", $$v)},expression:"form.logo"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 8, offset: 8 }}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" 提交 ")]),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":_vm.onClose}},[_vm._v(" 取消 ")])],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
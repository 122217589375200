<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>小程序</a-breadcrumb-item>
              <a-breadcrumb-item>工作台</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div v-if="!visible" class="Enter-top-right">
          <a-button
            style="margin: 0 15px"
            type="primary"
            @click.prevent="showDrawer"
          >
            新增模块
          </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="!visible"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <a-table
              :row-selection="rowSelection"
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="dashboardlist"
              :rowClassName="rowClassName"
            >
              <template slot="action" slot-scope="text, record">
                <a @click="onEdit(record)">编辑</a>&nbsp;&nbsp;
                <a-popconfirm
                  title="你确认要删除该模块么?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="onDetele(record)"
                  @cancel="cancel"
                >
                  <a>删除</a>
                </a-popconfirm>
              </template>
              <span slot="status" slot-scope="text, record">
                <a-switch
                  :checked="text == 1 ? true : false"
                  default-checked
                  @change="onstate(record)"
                />
              </span>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <div>
          <a-form-model
            ref="ruleForm"
            :rules="rules"
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="名称">
              <a-input v-model="form.name" />
            </a-form-model-item>
            <a-form-model-item label="路径">
              <a-input v-model="form.path" />
            </a-form-model-item>
            <a-form-model-item label="序号">
              <a-input v-model="form.num" />
            </a-form-model-item>
            <a-form-model-item label="颜色">
              <a-input v-model="form.color" />
            </a-form-model-item>
            <a-form-model-item label="图标">
              <a-input v-model="form.logo" />
            </a-form-model-item>
            <a-form-item :wrapper-col="{ span: 8, offset: 8 }">
              <a-button type="primary" @click="onSubmit"> 提交 </a-button>
              <a-button @click="onClose" style="margin-left: 10px">
                取消
              </a-button>
            </a-form-item>
          </a-form-model>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '模块名称',
    dataIndex: 'name',
  },
  {
    title: '模块路径',
    dataIndex: 'path',
    scopedSlots: { customRender: 'path' },
  },
  {
    title: '模块序号',
    dataIndex: 'num',
  },
  {
    title: '是否首页',
    dataIndex: 'isHome',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import http from '../../../http'

export default {
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      columns,
      title: '',
      visible: false,
      placement: 'right',
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      params: {
        current: 1,
        pageSize: 10,
      },
      total: 0,
      dashboardlist: [],
      value: 'name',
      key: '',
      selectedRowKey: [],
      Id: '',
      form: {
        id: '',
        name: '',
        path: '',
        num: '',
        color: '',
        logo: '',
      },
    }
  },
  filters: {},
  methods: {
    onClose() {
      this.visible = false
      this.form = {
        id: '',
        name: '',
        path: '',
        num: '',
        logo: '',
      }
    },
    onSubmit() {
      //   console.log("submit!", this.form);
      if (this.form.id == '') {
        this.addxcxconfig(this.form)
      } else {
        this.putxcxdashboard(this.form)
      }
    },
    onEdit(item) {
      this.form.id = item.id
      this.visible = true
      this.title = '编辑'
      this.getxcxdashboardid(item.id)
    },
    // 新增
    async addxcxconfig(form) {
      try {
        const res = await http.addxcxconfig(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async putxcxdashboard(form) {
      try {
        const res = await http.putxcxdashboard(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onstate(e) {
      //   console.log(e);
      this.getxcxdashboardstate(e.id, '1')
    },
    onpagesize(e) {
      this.params.current = e
      this.getxcxdashboard()
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
      this.$store.commit('selectKeys')
    },
    async getxcxdashboard() {
      try {
        const res = await http.getxcxdashboard(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.dashboardlist = data.dashboards.records
          this.total = data.dashboards.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getxcxdashboardstate(id, isHome) {
      try {
        const res = await http.getxcxdashboardstate(id, isHome)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async getxcxdashboardid(id) {
      try {
        const res = await http.getxcxdashboardid(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data.dashboard);
          this.form.id = data.dashboard.id
          this.form.name = data.dashboard.name
          this.form.path = data.dashboard.path
          this.form.num = data.dashboard.num
          this.form.logo = data.dashboard.logo
          this.form.color = data.dashboard.color
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    // 删除
    onDetele(e) {
      this.deletexcxdashboard(e.id)
    },
    async deletexcxdashboard(id) {
      try {
        const res = await http.deletexcxdashboard(id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.params.current = 1
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getxcxdashboard()
      }, 100)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },
  mounted() {
    this.getxcxdashboard()
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
  },
  computed: {},
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  height: 60px;
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
